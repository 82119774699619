// axios
import * as axios from "axios";
import store from "./store/store";
import AuthService from "./services/AuthService";
import { BaseConfig } from "./store/api/config";

const coreUrl = `${BaseConfig.COREAPI}`;

const bhUrl = `${BaseConfig.BHAPI}`;
const bhAPIInstance = new axios.create({ baseURL: bhUrl });
const bhPublicAPIInstance = new axios.create({ baseURL: bhUrl });

const authservice = new AuthService();

bhAPIInstance.interceptors.request.use(
  async config => {
    store.dispatch("setIsProgressing", true);
    let token = await authservice.getSilentToken("bh");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      return store.dispatch("login");
    }
    return config;
  },
  function(err) {
    console.log(err);
    store.dispatch("setIsProgressing", false);
    if (err.response.status === 401) {
      console.log("DEBUG: boat harbour API session expired, return to login page");
      return store.dispatch("login");
    } else {
      console.log(err.response.status);
    }
    return Promise.reject(err);
  }
);

bhAPIInstance.interceptors.response.use(
  function(response) {
    store.dispatch("setIsProgressing", false);
    return response;
  },
  function(err) {
    console.log(err);
    store.dispatch("setIsProgressing", false);
    if (err.response.status === 401) {
      console.log("DEBUG: boat harbour API session expired, return to login page");
      return store.dispatch("login");
    } else {
      console.log(err.response.status);
    }
    return Promise.reject(err);
  }
);

export const axiosInstance = {
  bhAPIInstance,
  bhPublicAPIInstance
};
