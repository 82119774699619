import Vue from 'vue'
import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue'
import VxCard from './components/vx-card/VxCard.vue'
import VxList from './components/vx-list/VxList.vue'
import VxBreadcrumb from './layouts/components/VxBreadcrumb.vue'
import FeatherIcon from './components/FeatherIcon.vue'
import VxInputGroup from './components/vx-input-group/VxInputGroup.vue'
import ViewFile from "@/components/ViewFile";
import Terms from "@/components/Terms";
import NoData from "@/components/NoData";
import PreviewFiles from "@/components/FileViewer/PreviewFiles";
import PreviewFileSideBar from "./components/FileViewer/PreviewFileSideBar.vue";
import ImageViewer from "@/components/FileViewer/ImageViewer"
import PdfViewer from "@/components/FileViewer/PdfViewer";
import VideoViewer from "@/components/FileViewer/VideoViewer";
import NoFile from "@/components/FileViewer/NoFile";

Vue.component(VxTooltip.name, VxTooltip)
Vue.component(VxCard.name, VxCard)
Vue.component(VxList.name, VxList)
Vue.component(VxBreadcrumb.name, VxBreadcrumb)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxInputGroup.name, VxInputGroup)
Vue.component(ViewFile.name, ViewFile)
Vue.component(Terms.name, Terms)
Vue.component(NoData.name, NoData)
Vue.component(PreviewFiles.name, PreviewFiles)
Vue.component(PreviewFileSideBar.name, PreviewFileSideBar)
Vue.component(ImageViewer.name, ImageViewer)
Vue.component(PdfViewer.name, PdfViewer)
Vue.component(VideoViewer.name, VideoViewer)
Vue.component(NoFile.name, NoFile)

// v-select component
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
    Deselect: {
        render: createElement => createElement('feather-icon', {
            props: {
                icon: 'XIcon',
                svgClasses: 'w-4 h-4 mt-1'
            }
        })
    },
    OpenIndicator: {
        render: createElement => createElement('feather-icon', {
            props: {
                icon: 'ChevronDownIcon',
                svgClasses: 'w-5 h-5'
            }
        })
    }
})

Vue.component(vSelect)