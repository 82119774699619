import Vue from 'vue'
import App from './App.vue'

import ThemifyIcon from "vue-themify-icons";
Vue.use(ThemifyIcon)

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

// Filters
import './filters/filters.js'

import { ValidationObserver } from 'vee-validate';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';

import { MaskedTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
Vue.use(MaskedTextBoxPlugin);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import vue2Dropzone from 'vue2-dropzone'
Vue.use(vue2Dropzone)

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

require('./assets/css/iconfont.css')

Vue.config.productionTip = false

import x5GMaps from 'x5-gmaps'
Vue.use(x5GMaps, { key: 'AIzaSyBtrjKngQYgKdEJW5lBhw4r-gr7pmveQtU', libraries: ['places'], v: 3.38 })

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
