const uploadAllowedFile =
    ".csv,.jpg,.jpeg,.png,.pdf,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.msg";
const FileExt = [
    ".pdf",
    ".xls",
    ".xlsx",
    ".csv",
    ".ppt",
    ".pptx",
    ".doc",
    ".docx",
    ".msg",
];
const ImageExt = [".jpg", ".jpeg", ".png", ".gif"];
const VideoExt = [".mp4"];
const DownloadableFiles = [
    ".xls",
    ".xlsx",
    ".csv",
    ".ppt",
    ".pptx",
    ".doc",
    ".docx",
    ".msg",
];
const AcceptedFiles = ".png,.jpg,.pdf,.jpeg";

export const FileConfig = {
    uploadAllowedFile,
    FileExt,
    ImageExt,
    DownloadableFiles,
    VideoExt,
    AcceptedFiles,
};