<template>
<div>
    <vs-popup fullscreen title="Terms And Conditions" :active.sync="isOpenTerms">
        <vx-pdf :key="fileURL" :src="fileURL" />
    </vs-popup>
</div>
</template>

<script>
export default {
    name: "ViewFile",

    mounted(){
        const next =this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickCloseButton, false);
    },
    props: {
        isOpenTerms: {
            type: Boolean,
            required: true,
            default: false,
        },
        URL: {
            type: String,
            required: true,
            default: "",
        },
    },
    data() {
        return {
            fileURL: "",
        };
    },
    watch: {
        URL: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.fileURL = newValue;
            }
        },
    },
    methods: {
      onClickCloseButton() {
        this.$emit('closeWindow')
      }
    }
}
</script>
