<template>
<div id="theme-customizer">
    <vs-sidebar :click-not-close="this.keepWindowOpen" hidden-background position-left v-model="openlocationwindow" class="width-set items-no-padding">
        <div class="h-full">
            <div class="customizer-header mt-6 flex items-center justify-between px-6">
                <div>
                    <h4>File</h4>
                </div>
                <feather-icon icon="XIcon" @click.stop="closeWindow" class="cursor-pointer"></feather-icon>
            </div>
            <vs-divider class="mb-0" />
            <div class="p-4">
                <vx-pdf :key="fileURL" v-if="fileURL && fileExt.includes(fileFormat)" :src="fileURL" />
                <vx-video :key="fileURL" v-else-if="fileURL && videoExt.includes(fileFormat)" :src="fileURL" />
                <vx-image :key="fileURL" v-else-if="fileURL && imageExt.includes(fileFormat)" :src="fileURL" />
                <NoFile v-else />
            </div>
        </div>
    </vs-sidebar>
</div>
</template>

<script>
import {
    FileConfig
} from "../../store/fileConfig.js";

export default {
    name: "vx-previewfile",
    data() {
        return {
            active: false,
            keepWindowOpen: true,
            fileURL: "",
            fileFormat: "",
            fileExt: FileConfig.FileExt,
            videoExt: FileConfig.VideoExt,
            imageExt: FileConfig.ImageExt,
        };
    },
    components: {},
    props: {
        openlocationwindow: {
            type: Boolean,
            required: true,
            default: true,
        },
        previewFileData: {
            type: Object,
            required: true
        },
    },
    watch: {
        previewFileData: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.fileURL = newValue.fileURL;
                this.fileFormat = newValue.fileFormat;
            }
        }
    },
    methods: {
        closeWindow() {
            this.$emit("closeWindow");
        },
    },
};
</script>

<style lang="scss">
#theme-customizer {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        width: 400px;
        max-width: 90vw;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
            0 5px 15px 0 rgba(0, 0, 0, 0.08);

        @media screen and (max-width: 1200px) {
            min-width: 100vw;
            margin: 0px;
            max-height: 100vh;
        }
    }

    .width-set>.vs-sidebar {
        width: 25% !important;
    }
}

.customizer-btn {
    position: fixed;
    top: 50%;
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 50000;

    .vs-icon {
        animation: spin 1.5s linear infinite;
    }
}

.scroll-area--customizer {
    height: calc(100% - 5rem);

    &:not(.ps) {
        overflow-y: auto;
    }
}

.vs-con-table .vs-con-tbody {
    overflow: hidden;
}
</style>
