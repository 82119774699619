<template>
<div>
    <span class="flex justify-center">
        <vs-icon icon="icon-file-minus" icon-pack="feather" size="large"></vs-icon>
    </span>
    <h4 class="mt-5 bold flex justify-center">
        No Document Found / Selected.
    </h4>
    <h6 class="mt-5 bold flex justify-center"> Please select a Document.</h6>
</div>
</template>

<script>
export default {
  name: 'vx-nofile',
};
</script>
