import Vue from "vue";
import Router from "vue-router";
import Store from "./store/store";
import { mapActions } from "vuex";
import { profileData } from "./store/api/profile";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  methods: {
    ...mapActions(["login"])
  },
  routes: [
    {
      path: "/",
      redirect: "login",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "/RegisterCar",
          name: "Register Car",
          component: () => import("@/views/RegisterCar/RegisterCar.vue"),
          meta: {
            requireAuth: false,
            userType: ["Customer"]
          }
        },
        {
          path: "/MyProfile",
          name: "profile",
          component: () => import("./views/MyProfile/AddProfile.vue"),
          meta: {
            requireAuth: true
          }
        },
        {
          path: "/Vehicles",
          name: "Vehicles",
          component: () => import("./views/Vehicles/Vehicles.vue"),
          meta: {
            requireAuth: true,
            userType: ["Customer"]
          }
        },
        {
          path: "/Vehicles/addCar",
          name: "addvehicle",
          component: () => import("./views/Vehicles/AddCar.vue"),
          meta: {
            parent: "Vehicles",
            requireAuth: true,
            userType: ["Customer"]
          }
        },
        {
          path: "/Vehicles/reNew/:id?",
          name: "renewpass",
          component: () => import("./views/Vehicles/BuyNew.vue"),
          meta: {
            parent: "Vehicles",
            requireAuth: true,
            userType: ["Customer"]
          }
        },
        {
          path: "/Vehicles/edit/:id?",
          name: "editvehicle",
          component: () => import("./views/Vehicles/EditCar.vue"),
          meta: {
            parent: "Vehicles",
            requireAuth: true,
            userType: ["Customer"]
          }
        },
        {
          path: "/OpenBallot",
          name: "OpenBallot",
          component: () => import("./views/Ballot/OpenBallot.vue"),
          meta: {
            requireAuth: true,
            userType: ["Customer"]
          }
        },
        {
          path: "/ParkingHistory",
          name: "ParkingHistory",
          component: () => import("./views/ParkingHistory/ParkingHistory.vue"),
          meta: {
            requireAuth: true,
            userType: ["Customer"]
          }
        }
      ]
    },
    {
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "/admin/home",
          name: "adminhome",
          component: () => import("@/views/Home.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator","User"]
          }
        },
        {
          path: "/user",
          name: "user",
          component: () => import("@/views/User/UserPage.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/Customers",
          name: "customers",
          component: () => import("@/views/Customer/CustomerPage.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/Customers/edit/:id?",
          name: "customers",
          component: () => import("@/views/Customer/CustomerProfile.vue"),
          meta: {
            parent: "customer",
            requireAuth: true,
            userType: "Administrator"
          }
        },
        {
          path: "/admin/seasonpass",
          name: "seasonpass",
          component: () => import("@/views/product/seasonpass.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/ActiveRegistration",
          name: "activeReg",
          component: () =>
            import("@/views/Registration/ActiveRegistration.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator","User"]
          }
        },
        {
          path: "/admin/PendingRegistration",
          name: "pendingReg",
          component: () =>
            import("@/views/Registration/PendingRegistration.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator","User"]
          }
        },
        {
          path: "/admin/processingRegistration",
          name: "processingReg",
          component: () =>
            import("@/views/Registration/ProcessingRegistration.vue"),
          meta: {
            parent:"processingReg",
            requireAuth: true,
            userType: ["Administrator","User"]
          }
        },
        {
          path: "/admin/ballot",
          name: "ballot",
          component: () => import("@/views/Ballot/BallotList.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/ballot/add/:id?",
          name: "addballot",
          component: () => import("@/views/Ballot/AddBallot.vue"),
          meta: {
            parent: "AdminBallot",
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/ballot/view/:id?",
          name: "addballot",
          component: () => import("@/views/Ballot/ViewBallot.vue"),
          meta: {
            parent: "AdminBallot",
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/localpostcode",
          name: "localpostcode",
          component: () => import("@/views/LocalPostcode/LocalPostcode.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator","User"]
          }
        },
        {
          path: "/admin/localpostcode/add/",
          name: "addlocalpostcodes",
          component: () => import("@/views/LocalPostcode/AddLocalPostcode.vue"),
          meta: {
            parent: "Localpostcode",
            requireAuth: true,
            userType: ["Administrator","User"]
          }
        },
        {
          path: "/admin/promo",
          name: "promo",
          component: () => import("@/views/Promo/PromoList.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/Financereport ",
          name: "Financereport",
          component: () => import("@/views/Reports/FinanceReports.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/CustomerReport",
          name: "Financereport",
          component: () => import("@/views/Reports/CustomerReport.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/blacklist",
          name: "blacklist",
          component: () => import("@/views/BlackList/BlackList.vue"),
          meta: {
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/blacklist/add/:id?",
          name: "addblacklist",
          component: () => import("@/views/BlackList/AddBlackList.vue"),
          meta: {
            parent: "blacklist",
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/admin/blacklist/revoke/:id?",
          name: "revokeblacklist",
          component: () => import("@/views/BlackList/ManageBlackList.vue"),
          meta: {
            parent: "blacklist",
            requireAuth: true,
            userType: ["Administrator"]
          }
        },
        {
          path: "/VisitHistory",
          name: "visitHistory",
          component: () => import("@/views/ParkingHistory/VisitHistory.vue"),
          meta: {
            requireAuth: true,
            userType: ["User","Administrator"]
          }
        }
      ]
    },
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        {
          path: "/unauthorized",
          name: "unauthorized",
          component: () => import("@/views/common/unauthorized.vue")
        },
        {
          path: "/login",
          name: "login",
          component: () => import("@/views/common/PreLogin.vue")
        },
        {
          path: "/Ballot/OptIn/:id/action/:action",
          name: "ballotaction",
          component: () => import("@/views/Ballot/optinoptout.vue")
        },
        {
          path: "/completesignup",
          name: "completesignup",
          component: () => import("@/views/common/completesignup.vue")
        },
        {
          path: "/relogin",
          name: "relogin",
          component: () => import("@/views/common/relogin.vue")
        },
        {
          path: "/completeLogin",
          name: "completeLogin",
          component: () => import("@/views/common/completeLogin.vue")
        },
        {
          path: "/signup",
          name: "signup",
          component: () => import("@/views/common/signup.vue")
        },
        {
          path: "/completeprofile",
          name: "completeprofile",
          component: () => import("@/views/common/completeProfile.vue")
        },
        {
          path: "/logout",
          name: "logout",
          component: () => import("@/views/common/Logout.vue")
        },
        {
          path: "/OpenBallot/:id?/user/:id?",
          name: "openballot",
          component: () => import("@/views/common/BallotOptin.vue")
        }
      ]
    },
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        {
          path: "/*",
          name: "unauthorized",
          component: () => import("@/views/common/unauthorized.vue")
        },
      ]
    }
  ]
});

router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  Store.dispatch("setGlobalMessage", "");
});

router.beforeEach(async (to, from, next) => {
  Store.dispatch("setGlobalMessage", "");
  if (to.meta.requireAuth) {
    if (!Store.state.isAuthenticated) {
      return router.push("/login");
    }

    if (to.meta.userType) {
      let user = await profileData.SearchMyProfile();
      if (!user || user.azureObjectId === '00000000-0000-0000-0000-000000000000') {
        router.push("/completeprofile");
        return;
      }

      if (!to.meta.userType.includes(user.userType)) {
        router.push("/unauthorised");
        Store.dispatch(
          "setGlobalMessage",
          "You are not allowed to perform this action."
        );
        return next(false);
      }
    }
  }

  return next();
});

export default router;
