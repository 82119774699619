<template>
  <div>
    <vx-pdf v-if="previewData.isFile" :src="previewData.downloadUrl" />
    <vx-image
      v-if="previewData.isImage"
      :src="previewData.downloadUrl"
    />
    <NoData v-else class="mt-base" :headTitle="noData.title" :description="noData.description"></NoData>
  </div>
</template>

<script>
export default {
  name: "vx-preview",
  data() {
    return {
      previewData: {},
      noData: {
          title: "No File Selected",
          description: "Please select a Document",
      },
    };
  },
  props: {
    fileData: {
      type: Object,
      required: true,
      default: () => undefined,
    },
  },
  watch: {
    fileData: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== undefined) {
          this.previewData = newValue
        }
      },
    },
  },
  methods: {},
};
</script>
