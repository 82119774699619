<template>
  <video
    style="height: auto; width: auto; max-width: 600px; max-height: 600px;"
    class="mb-4 w-full"
    controls
  >
    <source :src="videoUrl" />
    Your browser does not support HTML video.
  </video>
</template>

<script>
export default {
  name: "vx-video",
  data() {
    return {
      videoUrl: ""
    };
  },
  props: ["src"],
  watch: {
    src: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.videoUrl = newValue;
      }
    }
  }
};
</script>
