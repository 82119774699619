<template>
<div>
    <span class="flex justify-center">
        <vs-icon icon="icon-file-minus" icon-pack="feather" size="large"></vs-icon>
    </span>
    <h4 class="mt-5 bold flex justify-center" v-show="headTitle">
        {{headTitle}}
    </h4>
    <h6 class="mt-5 bold flex justify-center" v-show="description"> {{description}}</h6>
</div>
</template>

<script>
export default {
  name:"NoData",
  props: {
        headTitle: {
            type: String,
            required: false,
            default: ()=> ""
        },
        description: {
            type: String,
            required: false,
            default: ()=> ""
        }
    },
};
</script>
