import AuthService from "../services/AuthService";
import router from "../router";
import { profileData } from "./api/profile";

const authservice = new AuthService();

const actions = {
  async completeLogin({ commit }, user) {
    if (user !== undefined && user !== null) {
      commit("updateIsAuthenticated", true);
      let loginUser = await profileData.SearchMyProfile();
      if (loginUser.userType === "Administrator" || loginUser.userType === "User") {
        router.push("/admin/home");
      } else {
        router.push("/MyProfile");
      }
    }
  },

  switchCompany({ commit }, user) {
    window.location.reload();
  },

  logout({ commit }) {
    commit("updateIsAuthenticated", false);
    authservice.logout();
  },

  editProfile({ commit }) {
    authservice.editProfile();
  },

  setIsProgressing({commit}, value){
    commit("IsProgressing", value);
  },

  setGlobalMessage({commit}, value){
    commit("globalMessage", value);
  },

  setApplicationVersion({commit}, value){
    commit("updateApplicationVersion", value);
  },

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit("UPDATE_VERTICAL_NAV_MENU_WIDTH", width);
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit("UPDATE_STARRED_PAGE", payload);
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_LIMITED", list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_MORE", list);
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit("TOGGLE_CONTENT_OVERLAY");
  },
  updateTheme({ commit }, val) {
    commit("UPDATE_THEME", val);
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo({ commit }, payload) {
    commit("UPDATE_USER_INFO", payload);
  }
};

export default actions;
