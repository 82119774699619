<template>
<div id="iframe-wrapper">
    <object :style="iframe.style" :height="iframe.style.height" :width="iframe.style.width" :data="iframe.src"></object>
</div>
</template>

<script>

export default {
    name: "vx-pdf",
    data() {
        return {
            loaded: false,
            iframe: {
                src: '',
                style: {
                    position: 'absolute',
                    width: '90%',
                    height: '80vh',
                },
                wrapperStyle: null,
                height: '90%',
                width: '600px',
                position: 'absolute'
            }
        }
    },
    props: ['src'],
    mounted() {
        // this.iframe.style = {
        //     position: 'absolute',
        //     width: '98%',
        //     height: '700px'
        // }
        this.iframe.src = this.src
        this.loaded = true
    }
}
</script>
